import * as React from "react"
import { Link } from "gatsby" 
import Seo from "../components/seo"

const pageStyles = {
  backgroundColor: "#FFFFFF",
  padding: "10% 0% 100% 1%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",

}
const linkStyles = {
    color: "#0000ff",
    padding: "0%",
    fontSize: "2.5em",
    fontWeight: "bold", 
  }
const headingStyles = {
    fontWeight: "600",
    lineHeight: "1.375",
    fontSize: "2.75em",
    color:"#000000",
    marginTop: "0",
    marginBottom: "20",
    maxWidth: "320",
    margin: "0",
    padding:"0",   
   }
const NotFoundPage = () => {
  return (
    <main style={pageStyles}> 
      <h1 style={headingStyles}>ОЙ! 😔 Ошибка 404. Нет такой страницы...</h1>
     <br></br>
      <ul style={linkStyles}>
		<li><Link to="/">Главная страница</Link></li>
	 </ul>
    </main>
  )
}
export const Head = () => (
  <Seo title="Ошибка 404. Страница не найдена!"
    description="DOSUGZ: Ошибка 404. Страница не найдена!">
    <meta name="robots" content="noindex,nofollow"/>
  </Seo>
)
export default NotFoundPage
